import Amplify from '@aws-amplify/core'
import { Box, Typography } from '@material-ui/core'
import { navigate } from 'gatsby'
import { TFunction } from 'i18next'
import React, { useEffect, useState } from 'react'
import { isIOS, isMobileSafari } from 'react-device-detect'
import { withTranslation } from 'react-i18next'
import { TrackingCase } from '../../../utils/trackingCases'
import { useAppState } from '../../appState'
import ExtensionWarningModal from '../../components/extensionWarningModal'
import { baseURL, ENDPOINTS, ROUTES } from '../../constants'
import useBrandJoinFlow from '../../hooks/useBrandJoinFlow'
import useNavigateToExtStore from '../../hooks/useNavigateToExtStore'
import Layout from '../../layouts/defaultLayout'
import { tracker } from '../../systemLogs'
import CHRUploadAvatar from '../AmplifyUpload'
import ContentContainer from '../contentContainer'
import AddChirpyestModal from '../joinModales/addChirpyest'
import SEO from '../seo'
import {
  checkIfSubmittable,
  profileInfoValidation,
} from './../../../utils/validation'
import awsConfig from './../../aws-exports'
import axios from './../../axios'
import CHRButton from './../button'
import CHRInput from './../input'
import { styles } from './styles'

Amplify.configure(awsConfig)

interface PersonalDataAttributes {
  imageUrl: string
  firstName: string
  lastName: string
  username: string
}

interface PersonalInfoPageProps {
  t: TFunction
  personalData: PersonalDataAttributes
}

const deviceType = () => {
  const ua = navigator.userAgent
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet'
  } else if (
    /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
      ua
    )
  ) {
    return 'mobile'
  }
  return 'desktop'
}

const parseFirstName = (fullName: string | undefined): string => {
  const isFirstVisit = window.localStorage.getItem('signUp') === 'firstVisit'
  if (!fullName || isFirstVisit) return ''
  return fullName.split(' ')[0] || ''
}
const parseLastName = (fullName: string | undefined): string => {
  const isFirstVisit = window.localStorage.getItem('signUp') === 'firstVisit'
  if (!fullName || isFirstVisit) return ''
  return fullName.split(' ')[1] || ''
}
const PersonalInfo = (props: PersonalInfoPageProps) => {
  const [hasExt, setHasExt] = useState(false)
  const { t } = props
  const [appState, dispatch] = useAppState()
  const classes = styles()
  const [userInfo, setUserInfo] = useState({
    firstName:
      appState.userProfile.loginProvider == 'SignInWithApple'
        ? ''
        : parseFirstName(appState.userProfile.fullName),
    lastName:
      appState.userProfile.loginProvider == 'SignInWithApple'
        ? ''
        : parseLastName(appState.userProfile.fullName),
    userName: appState.userProfile.userName || '',
  })

  const [
    isExtWarningModalOpen,
    setExtWarningModalOpen,
    navigateToStore,
  ] = useNavigateToExtStore()

  const [firstNameError, setFirstNameError] = useState('')
  const [lastNameError, setLastNameError] = useState('')
  const [userNameError, setUserNameError] = useState('')

  const [requestStatus, setRequestStatus] = useState({
    requestSuccessMsg: '',
    requestErrorMsg: '',
  })
  const [isSubmitting, setIsSubmitting] = useState(false)

  const {
    joinFlowBrandName,
    generateBrandTrackingLinkForUser,
    getBrandDetailsFromStorage,
  } = useBrandJoinFlow()

  useEffect(() => {
    if (requestStatus.requestSuccessMsg) {
      dispatch({
        type: 'UPDATE_USER_DATA',
        userProfile: {
          ...appState.userProfile,
          userName: userInfo.userName,
          fullName: userInfo.firstName + ' ' + userInfo.lastName,
        },
      })
    }
    return
  }, [requestStatus.requestSuccessMsg])

  const handleTextChange = (event: any, fieldName: string) => {
    if (fieldName == 'firstName') {
      setFirstNameError('')
    } else if (fieldName === 'lastName') {
      setLastNameError('')
    } else {
      setUserNameError('')
    }
    setUserInfo({ ...userInfo, [fieldName]: event.target.value })
  }

  const handleInfoSubmission = async (formSubmitting: boolean = false) => {
    tracker.track(TrackingCase.UserTracking, `complete profile click`, {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      userName: userInfo.userName,
      userId: appState.userId,
    })
    setIsSubmitting(true)
    try {
      const isFormValid = await profileInfoValidation({
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        userName: userInfo.userName,
      })

      const userData = {
        name: `${userInfo.firstName} ${userInfo.lastName}`,
        username: userInfo.userName,
      }

      const result = await axios.patch(
        `${baseURL}/${ENDPOINTS.user.replace(':id', appState.userId)}`,
        userData
      )

      if (joinFlowBrandName) {
        window.location.replace(
          generateBrandTrackingLinkForUser(
            getBrandDetailsFromStorage(),
            {
              userId: appState.userId ?? '0',
              username: userInfo.userName ?? '',
            },
            Boolean(appState.userId)
          )
        )
        return
      }

      if (result) {
        setRequestStatus({ requestSuccessMsg: t('messages.yourInfoUpdated') })
      }

      if (formSubmitting && hasExt) {
        // Take user directly to user/member route without showing extension popups
        navigate(ROUTES.member)
      }
      if (deviceType() === 'mobile') {
        if (result.status === 200) {
          if (isIOS && isMobileSafari) {
            navigate('/app/onboarding?howItWorks=true')
          } else {
            navigate(ROUTES.member)
          }
        }
      }
      // navigate(ROUTES.personalInfo)
    } catch (error) {
      if (error.errors) {
        const errors = error?.inner?.reduce(
          (accumulator: {}, ele) => {
            accumulator[ele?.path] = ele.message
            return accumulator
          },
          { firstName: '', lastName: '', userName: '' }
        )
        setFirstNameError(errors.firstName)
        setLastNameError(errors.lastName)
        setUserNameError(errors.userName)
      } else {
        if (error.response?.data?.message === 'username is already exists') {
          setUserNameError(t(`dashboard.uniqueUsername`))
        }
        tracker.track(TrackingCase.UserTracking, `complete profile failed`, {
          errorMessage: error.message,
          userId: appState.userId,
        })
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  if (window.localStorage.getItem('extensionDownloaded')) {
    setHasExt(true)
  }

  useEffect(() => {
    if (window.localStorage.getItem('redirectedFromRouting')) {
      handleInfoSubmission()
    }

    if (deviceType() === 'mobile') {
      let profile = window.localStorage.getItem('profile')
      if (profile) {
        if (JSON.parse(profile)?.username === null) {
          window.localStorage.setItem('addChirpyestModal', 'true')
        }
      }
    }
  }, [])

  return (
    <Layout>
      <SEO title={t('personalInfo.metaTitle')} />
      <ContentContainer>
        <div className={classes.container}>
          <form className={classes.container}>
            <Typography variant="h1" className={classes.title}>
              {t('personalInfo.title')}
            </Typography>
            <Typography variant="h3" component="p">
              {t('personalInfo.subtitle')}
            </Typography>
            <div className={classes.uploadPicture}>
              <label
                className={classes.uploadPictureContainer}
                htmlFor="circle-upload-avatar"
              >
                <CHRUploadAvatar
                  inputId="upload-avatar"
                  circleInputId="circle-upload-avatar"
                />
              </label>
              <div className={classes.uploadPictureText}>
                <label
                  htmlFor="upload-avatar"
                  className={classes.clickHereToUpload}
                >
                  {appState.userProfile?.imageError && (
                    <Typography
                      variant="subtitle1"
                      component="p"
                      className={`${classes.errorMsg} ${classes.uploadError}`}
                    >
                      {t('messages.uploadFailed')}
                    </Typography>
                  )}
                  <Typography
                    variant="subtitle1"
                    component="p"
                    className={classes.clickHere}
                  >
                    <span className={classes.pictureIsOptional}>
                      {t('personalInfo.pictureIsOptional')}
                    </span>
                    <br />
                    <span>
                      <span className={classes.tapText}>
                        {t('personalInfo.tap')}
                      </span>
                      <span className={classes.clickText}>
                        {t('personalInfo.click')}
                      </span>
                      {t('personalInfo.uploadPictureText')}
                    </span>
                  </Typography>
                </label>
              </div>
            </div>
            <div className={classes.inputContainer}>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.errorMsg}
              >
                {firstNameError}
              </Typography>
              <CHRInput
                value={userInfo.firstName}
                onChange={() => handleTextChange(event, 'firstName')}
                placeholder={t('shared.firstName')}
                type="text"
                error={firstNameError}
                required
                onKeyPress={event =>
                  !isSubmitting &&
                  checkIfSubmittable(event, handleInfoSubmission)
                }
              />
            </div>
            <div className={classes.inputContainer}>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.errorMsg}
              >
                {lastNameError}
              </Typography>
              <CHRInput
                value={userInfo.lastName}
                onChange={() => handleTextChange(event, 'lastName')}
                placeholder={t('shared.lastName')}
                type="text"
                error={lastNameError}
                required
                onKeyPress={event =>
                  !isSubmitting &&
                  checkIfSubmittable(event, handleInfoSubmission)
                }
              />
            </div>
            <div className={classes.inputContainer}>
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.errorMsg}
              >
                {userNameError}
              </Typography>
              <CHRInput
                value={userInfo.userName}
                onChange={() => handleTextChange(event, 'userName')}
                placeholder={t('shared.username')}
                type="text"
                error={userNameError}
                required
                onKeyPress={event =>
                  !isSubmitting &&
                  checkIfSubmittable(event, handleInfoSubmission)
                }
              />
            </div>
            <div className={classes.buttonContainer}>
              <CHRButton
                onClick={() => {
                  handleInfoSubmission(true)
                }}
                label={
                  joinFlowBrandName ? (
                    <Box pl={joinFlowBrandName ? 4 : 0}>
                      <Box>
                        {t('shared.finish') as string}
                        {joinFlowBrandName ? ' & continue to' : ''}
                      </Box>
                      <Box>{joinFlowBrandName}</Box>
                    </Box>
                  ) : (
                    <>{t('shared.letsGo')}</>
                  )
                }
                isSubmitting={isSubmitting}
                disabled={isSubmitting}
              />
              <Typography
                variant="subtitle1"
                component="p"
                className={classes.successMsg}
              >
                {requestStatus.requestSuccessMsg}
              </Typography>
            </div>
          </form>
        </div>
        <AddChirpyestModal
          openStatus={
            !hasExt &&
            !!requestStatus.requestSuccessMsg &&
            deviceType() !== 'mobile'
          }
          handleClose={() => navigate(ROUTES.member)}
          onAddClick={() => {
            navigateToStore()
            navigate(ROUTES.member)
          }}
        />
        <ExtensionWarningModal
          isOpen={isExtWarningModalOpen}
          onClose={() => setExtWarningModalOpen(false)}
          onCTAButtonClicked={() => navigate(ROUTES.member)}
        />
      </ContentContainer>
    </Layout>
  )
}

export default withTranslation()(PersonalInfo)
