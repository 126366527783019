import classNames from 'classnames'
import { TFunction } from 'i18next'
import * as React from 'react'
import { withTranslation } from 'react-i18next'
import { useAppState } from '../../appState'
import { styles } from './styles'

interface BrandItemProps {
  name: string
  brandLogo: any
  cashBack?: string
  isCashbackShownInWebsite?: boolean
  isActive?: boolean
  customStyle: any
  t: TFunction
  link: string
  isCardOnLanding?: boolean
  onClick: () => void
}

const BrandItem = ({
  name,
  brandLogo,
  link,
  cashBack,
  isCashbackShownInWebsite = true,
  isActive,
  customStyle,
  isCardOnLanding,
  onClick,
  t,
  ...props
}: BrandItemProps) => {
  const classes = styles()
  const [appState] = useAppState()
  const cashBackClasses = classNames({
    [classes.cashBackText]: true,
    [classes.cashBackTextActive]: isActive,
    [customStyle]: customStyle,
  })
  const cardBackground = isCardOnLanding
    ? appState.username
      ? classes.whiteCard
      : classes.whiteCardOld
    : ''

  return (
    <div className={`${classes.card} ${cardBackground}`}>
      <a href={link} target="_blank" rel="noreferrer" onClick={onClick}>
        <img src={brandLogo} alt={name} className={classes.logo} />
        {cashBack && isCashbackShownInWebsite ? (
          <p className={cashBackClasses}>
            {cashBack} {t('home.cashBack')}
          </p>
        ) : (
          <p className={cashBackClasses}>{t('home.shopNow')}</p>
        )}
      </a>
    </div>
  )
}

export default withTranslation()(BrandItem)
