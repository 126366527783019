import * as moment from 'moment'
import { useEffect, useState } from 'react'
import { generateTrackingLink } from '../../utils/brandsHelper'
import { cashbackholicRefKey, ROUTES } from '../constants'

export interface JoinFlowBrandDetails {
  source: 'ref-join' | 'brands'
  origLink: string
  brandId?: string
  brandName: string
  url: string
  network: string
  trackingLink: string
  commission: string
  isCashbackShownInWebsite: boolean
  referrerId?: string
  date?: string
}

export interface UseBrandJoinFlow {
  joinFlowBrandName: string
  setBrandForJoinFlow: (brandDetails: JoinFlowBrandDetails) => void
  getBrandDetailsFromStorage: () => JoinFlowBrandDetails
  clearJoinFlowStorage: () => void
  generateBrandTrackingLinkForUser: (
    brand: any,
    user: { userId: string; username: string },
    showRedirectingPage: boolean
  ) => string
  checkJoinFlowExpiry: () => void
  setJoinFlowReferral: (id: string) => void
}

export const brandJoinFlowStorageKey = 'brandJoinFlow'

const brandJoinFlowReloadKey = 'reloaded'

export default function useBrandJoinFlow(): UseBrandJoinFlow {
  const [joinFlowBrandName, setJoinFlowBrandName] = useState('')

  const updateJoinFlowBrandName = (brandName: string) => {
    setJoinFlowBrandName(
      brandName.length > 30 ? brandName.slice(0, 30) + '...' : brandName
    )
  }

  const setBrandForJoinFlow = (brandDetails: JoinFlowBrandDetails) => {
    if (brandDetails.brandName) {
      window.sessionStorage.setItem(
        brandJoinFlowStorageKey,
        JSON.stringify({
          ...brandDetails,
          date: new Date().toISOString(),
        })
      )
      updateJoinFlowBrandName(brandDetails.brandName)
    }
  }

  const getBrandDetailsFromStorage = () => {
    const brand: JoinFlowBrandDetails = JSON.parse(
      window.sessionStorage.getItem(brandJoinFlowStorageKey) || '{}'
    )
    return brand
  }

  const clearJoinFlowStorage = () => {
    window.sessionStorage.removeItem(brandJoinFlowStorageKey)
    window.sessionStorage.removeItem(brandJoinFlowReloadKey)
  }

  const generateBrandTrackingLinkForUser = (
    brand: any,
    user: { userId: string; username: string },
    showRedirectingPage: boolean
  ) => {
    return generateTrackingLink(
      brand,
      { userId: user.userId, username: user.username },
      '',
      '',
      Boolean(showRedirectingPage)
    )
  }

  const setJoinFlowReferral = (id: string) => {
    const currentReferral = JSON.parse(
      window.localStorage.getItem('referralId') ?? '{}'
    ) as { id: string; time: string }

    let refId = id

    if (currentReferral.id === cashbackholicRefKey) {
      refId = `${cashbackholicRefKey}_${id}`
    }

    window.localStorage.setItem(
      'referralId',
      JSON.stringify({ id: refId, time: new Date().toISOString() })
    )
  }

  const checkJoinFlowExpiry = () => {
    const brand = getBrandDetailsFromStorage()
    if (
      brand.date &&
      Math.abs(moment(brand.date).diff(moment(), 'minutes')) >= 10
    ) {
      clearJoinFlowStorage()
    }
  }

  useEffect(() => {
    const brand = getBrandDetailsFromStorage()

    if (Object.keys(brand).length > 0 && brand.brandName) {
      updateJoinFlowBrandName(brand.brandName)
    }

    const isFromRefJoinPage = brand.source === 'ref-join'
    const isOnJoinPage = window.location.href.includes(ROUTES.join)
    const isPageReloaded = window.sessionStorage.getItem(brandJoinFlowReloadKey)

    if (isPageReloaded && isOnJoinPage && isFromRefJoinPage) {
      window.sessionStorage.removeItem(brandJoinFlowStorageKey)
      window.sessionStorage.removeItem(brandJoinFlowReloadKey)
      window.location.replace(brand.origLink as string)
    }

    window.addEventListener('beforeunload', () => {
      if (isOnJoinPage && isFromRefJoinPage) {
        window.sessionStorage.setItem('reloaded', 'true')
      }
    })

    return () => {
      window.removeEventListener('beforeunload', () => {
        window.sessionStorage.removeItem('reloaded')
      })
    }
  }, [])

  return {
    joinFlowBrandName,
    setBrandForJoinFlow,
    getBrandDetailsFromStorage,
    clearJoinFlowStorage,
    generateBrandTrackingLinkForUser,
    checkJoinFlowExpiry,
    setJoinFlowReferral,
  }
}
